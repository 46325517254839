
export default {
  name: 'Facebook',
  props: {
    color: {
      type: String,
      default: '#A7AEB8',
    },
    width: {
      type: String,
      default: '18',
    },
    height: {
      type: String,
      default: '18',
    },
  },

}
